import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'app/core/services/auth.service';

/**
 * Initiates the login process by redirecting the user to the identity provider.
 */
@Component({ selector: 'fs-login', template: '' })
export class LoginComponent implements OnInit {
  private readonly authService = inject(AuthService);

  private readonly activeRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  async ngOnInit() {
    const encodedParam = this.activeRoute.snapshot.queryParamMap.get('url');

    try {
      this.authService.login(encodedParam);
    } catch (error) {
      await this.authService.logout();
      await this.router.navigate(['/', encodedParam], { replaceUrl: true });
    }
  }
}
