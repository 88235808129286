import { NgModule } from '@angular/core';
import { provideAuth } from 'angular-auth-oidc-client';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';
import { fusionAuthConfig } from './config/fusionAuth.config';
import { OAuthCallbacks } from './config/oauth-callbacks';

@NgModule({
  imports: [AuthRoutingModule],
  declarations: [LoginRedirectComponent, LoginComponent],
  providers: [
    provideAuth({
      config: [fusionAuthConfig, ...Array.from(OAuthCallbacks.values()).map((config) => config.settings)],
    }),
  ],
})
export class AuthModule {}
