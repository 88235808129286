import { HttpInterceptorFn } from '@angular/common/http';

import { API_CONFIG } from '../config/api.config';

export const urlInterceptor: HttpInterceptorFn = (req, next) => {
  const config = req.context.get(API_CONFIG);

  if (!config) {
    return next(req);
  }

  const modifiedReq = req.clone({
    url: req.url.replace(config.identifier, config.baseUrl),
  });

  return next(modifiedReq);
};
