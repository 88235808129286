import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';

import { AuthService } from 'app/core/services/auth.service';

import { API_CONFIG } from '../config/api.config';

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
  const config = req.context.get(API_CONFIG);

  if (!config?.useAuth) {
    return next(req);
  }

  const authService = inject(AuthService);

  return authService.getAccessToken().pipe(
    take(1),
    map((accessToken) =>
      req.clone({
        headers: req.headers.set('authorization', accessToken).set('authtype', 'fusionauth'),
      }),
    ),
    switchMap((modifiedReq) => next(modifiedReq)),
  );
};
