import { HttpContext, HttpInterceptorFn } from '@angular/common/http';

import { API_CONFIG } from '../config/api.config';
import { ApiConfigFactory } from '../config/api.config-factory';

export const apiConfigInterceptor: HttpInterceptorFn = (req, next) => {
  const config = ApiConfigFactory.create(req.url);

  return next(
    req.clone({
      context: new HttpContext().set(API_CONFIG, config),
    }),
  );
};
