import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { ExternalAuthRedirectComponent } from './components/external-auth-redirect/external-auth-redirect.component';
import { LoginComponent } from './components/login/login.component';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';
import { authGuard } from './guards/auth.guard';
import { noAuthGuard } from './guards/no-auth.guard';

const routes: Route[] = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [noAuthGuard],
      },
      {
        path: 'oauth-redirect',
        component: LoginRedirectComponent,
        canActivate: [noAuthGuard],
      },
    ],
  },
  {
    path: 'oauth-callback/:integration',
    component: ExternalAuthRedirectComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
