import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { AuthService } from 'app/core/services/auth.service';

/**
 * Redirects the user to the home page after login.
 */
@Component({ selector: 'fs-login-redirect', template: '' })
export class LoginRedirectComponent implements OnInit {
  private authService = inject(AuthService);
  private router = inject(Router);

  async ngOnInit() {
    await lastValueFrom(this.authService.checkAuth());

    const customRedirectUrl = this.authService.getCustomAuthRedirectURL();

    if (customRedirectUrl) {
      this.authService.clearCustomAuthRedirectURL();
      void this.router.navigateByUrl(customRedirectUrl);
      return;
    }

    void this.router.navigate(['/workspaces']);
  }
}
