import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';

import { ToastModule } from '@site-mate/global-web-ui';

import { routes } from './app.routes';
import { AuthModule } from './core/auth/auth.module';
import { importAppHttpProviders } from './core/http/providers/http.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    importAppHttpProviders(),
    // Required at the root level to make the AuthModule providers available
    importProvidersFrom(AuthModule),
    importProvidersFrom(ToastModule),
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
  ],
};
