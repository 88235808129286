import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';

import { FlowsiteApiHttpClient } from '../clients/api.client';
import { httpClientFactory } from '../clients/client.factory';
import { GlobalApiHttpClient } from '../clients/global-api.client';
import { ApiIdentifier } from '../config/api.config';
import {
  apiConfigInterceptor, headersInterceptor, unauthorizedInterceptor, urlInterceptor,
} from '../interceptors';

export const importAppHttpProviders = () => [
  // Injects the HttpClient with custom interceptors
  provideHttpClient(
    withInterceptors([
      apiConfigInterceptor, // Adds the custom API config to the request
      urlInterceptor, // Adds the base URL to the request
      headersInterceptor, // Adds the custom headers to the request
      unauthorizedInterceptor, // Handles unauthorized requests
    ]),
  ),
  // Provides the custom HTTP clients
  {
    // Provides the Flowsite API client under the FlowsiteApiHttpClient token
    provide: FlowsiteApiHttpClient,
    useFactory: httpClientFactory.bind(null, ApiIdentifier.Flowsite),
    deps: [HttpClient],
  },
  {
    // Provides the Global API client under the GlobalApiHttpClient token
    provide: GlobalApiHttpClient,
    useFactory: httpClientFactory.bind(null, ApiIdentifier.Global),
    deps: [HttpClient],
  },
];
