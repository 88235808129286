import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { AuthService } from 'app/core/services/auth.service';

/**
 * A guard that checks if the user is not authenticated
 * and redirects to the workspace page if they are.
 * @returns True if the user is not authenticated, otherwise redirects to the workspace page.
 */
export const noAuthGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isAuthenticated = await lastValueFrom(authService.isAuthenticated());

  if (isAuthenticated) {
    void router.navigateByUrl('/workspaces');
    return false;
  }

  return true;
};
