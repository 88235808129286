import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'fs-not-found-page',
  template: `
    <div class="h-full p-8 flex justify-center items-start">
      <div class="rounded-md shadow-md mx-auto p-8 text-center">
        <h4 class="h4">The page you are looking for doesn't exist.</h4>
        <p class="mb-8">You may have mistyped the address or the page may have moved.</p>
        <a routerLink="/" class="shadow-md p-3 rounded-md">Click here to go to home</a>
      </div>
    </div>
  `,
  standalone: true,
  imports: [RouterModule],
})
export class NotFoundPage {}
