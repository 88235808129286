import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  AfterViewInit,
  Renderer2,
  ElementRef,
  inject,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'fs-api-portal',
  standalone: true,
  templateUrl: './api-portal.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ApiPortalComponent implements AfterViewInit {
  private readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);
  private readonly el = inject(ElementRef);
  private readonly meta = inject(Meta);
  private mutationObserver: MutationObserver | undefined;

  public basePath = '/docs/dashpivot/api';
  private elementsQuerySelector = 'elements-stoplight-project';

  ngOnInit(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  ngAfterViewInit() {
    this.updateAnchorLinks();

    this.mutationObserver = new MutationObserver(() => {
      this.updateAnchorLinks();
    });

    const targetNode = this.el.nativeElement.querySelector(this.elementsQuerySelector);
    if (targetNode) {
      this.mutationObserver.observe(targetNode, {
        childList: true,
        subtree: true,
      });
    }
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
    this.meta.removeTag("name='robots'");
  }

  /**
   * Fixes an issue where anchor links are not working as expected in Stoplight Elements Dev Portal
   * https://github.com/stoplightio/elements/issues/1699
   * https://github.com/stoplightio/elements/issues/2133
   *
   * The anchor links do not contain the base path of the API portal, so we need to update them.
   * Loop through all anchor links in the API portal and update the href attribute to include the base path.
   * Also fixes the issue where the page refreshes when clicking on an anchor link.
   */
  private updateAnchorLinks() {
    const anchorLinks = this.el.nativeElement.querySelectorAll(`${this.elementsQuerySelector} a[href^="#"]`);
    anchorLinks.forEach((link: HTMLAnchorElement) => {
      const anchor = link.getAttribute('href')?.split('#')[1];
      if (anchor && link.href.indexOf(this.basePath) === -1) {
        this.renderer.setAttribute(link, 'href', `${this.basePath}#${anchor}`);

        // Add click event listener to prevent full page refresh and scroll to the anchor
        this.renderer.listen(link, 'click', (event) => {
          event.preventDefault();
          void this.router.navigate([this.basePath], { fragment: anchor }).then(() => {
            this.scrollToAnchor(anchor);
          });
        });
      }
    });
  }

  private scrollToAnchor(anchor: string) {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
