import { BaseHttpClient } from './base.client';
import { ApiIdentifier } from '../config/api.config';

/**
 * Creates a new instance of the BaseHttpClient with the provided API identifier.
 * @param apiIdentifier The identifier of the API
 * @param http The HttpClient instance
 * @returns The BaseHttpClient instance
 */
export function httpClientFactory(apiIdentifier: ApiIdentifier) {
  return new BaseHttpClient(apiIdentifier);
}
