import { Maybe } from '@site-mate/sitemate-flowsite-shared';

import { IApiConfig, ApiConfigMap, ApiIdentifier } from './api.config';

export class ApiConfigFactory {
  private static config = ApiConfigMap;

  /**
   * Get the endpoint identifier from the URL.
   * The URL should be in the format of `@api-<name>/foo/bar` where `@api-<name>` is the identifier.
   * It will verify that the identifier is valid and return it, otherwise it will throw an error.
   * @param url The URL to get the endpoint identifier from.
   * @returns The endpoint identifier.
   */
  private static getEndpointIdentifier(url: string): ApiIdentifier {
    const error = new Error(`Invalid URL identifier: ${url}`);

    if (!url) {
      throw error;
    }

    const parts = url.split('/');

    if (parts.length < 2) {
      throw error;
    }

    const endpointIdentifier = parts[0] as ApiIdentifier;
    const availableEndpoints = Object.values(ApiIdentifier);

    if (!availableEndpoints.includes(endpointIdentifier)) {
      throw error;
    }
    return endpointIdentifier;
  }

  /**
   * Create API configuration by URL. It will identify the API by the first part of the URL.
   * @param url The URL to get the API configuration for.
   * @returns The API configuration.
   */
  public static create(url: string): Maybe<IApiConfig> {
    try {
      const endpointIdentifier = this.getEndpointIdentifier(url);

      return this.config[endpointIdentifier];
    } catch (error) {
      return undefined;
    }
  }
}
